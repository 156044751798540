import { useEffect, useState, useRef } from 'react'
import './WebAppWidget.css';
import utils from './utils';


function WebAppWidget ({brandData, recordingState, micData, language}) {

    const [test, setTest] = useState(language)

    useEffect(() => {utils.setupLanguage = language;setTest(language)}, [language])

    return (
        <div className="brand_page" style={{background: brandData['color'], width: '30vw', height: '50vw', maxHeight: '800px', maxWidth: '480px'}}>
            <div style={{background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8)', width: '100%', height: '100%', overflowY: 'scroll'}}>
                <div className="brand_container">
                    <div className="brand_header">
                        <img alt='VOTRE LOGO' src={brandData['image'].replace('./', process.env.PUBLIC_URL + '/')} style={{maxWidth: brandData['image_width'], height: 'auto', maxHeight: '100%'}}/>
                    </div>
                    <AlloMicro brandData={brandData} recordingState={recordingState} micData={micData} language={language}/>
                    <p className="footer_text">Powered by AlloReview</p>
                </div>
            </div>
        </div>
    )
}

function MainTexts({content}) {
    const elem = useRef();
    const [text, setText] = useState('');

    useEffect(() =>  {
        elem.current.style.fontSize = utils.getFontSize(content.length, 40);
    })

    useEffect(() => {
        elem.current.style.transition = 'transform 0.2s ease, opacity 0.2s ease'
        async function transit_text() {
            elem.current.style.transform = 'scale(0)'
            elem.current.style.opacity = '0'
            return new Promise(res => setTimeout(
                () => {
                    elem.current.style.transform = 'scale(1)'
                    elem.current.style.opacity = '1'
                    setText(content)
                },
                200
            ))
        }
        transit_text()
    }, [content])

    return (
        <p className="main_texts" ref={elem}><span>{text}</span></p>
    )
}

function AlloMicro ({brandData, recordingState, micData}) {
    const [postRecording, setPostRecording] = useState(false);
    const [preText, setPreText] = useState('catch_phrase')

    const audioData = {};

    useEffect(() => {
        async function displayPostRecordingPage () {
            return new Promise(res => {
                setTimeout(() => {
                    setPostRecording(true);
                    res();
                }, 200)
            })
        }
        if (recordingState == 'post') {
            // Function to upload file and audio

            displayPostRecordingPage();
        }

        if (recordingState == 'pre') {
            let i = 0;
            const switchText = setInterval(
                () => {
                    if (utils.getLanguage(brandData['text'][recordingState]['explaination']).length > 0) {
                        const ts = ['explaination', 'catch_phrase']
                        setPreText(ts[i]);
                        i = (i + 1)%2;
                    }
                },
                4000
            )
            return () => clearInterval(switchText)
        }
        
    }, [recordingState])

    brandData['text']['recording'] = {
        "catch_phrase": brandData['autres']['listening'],
        "explaination": {
            "en": "Once finished, touch to send!",
            "fr": "Une fois terminé, touchez pour envoyer !",
        }
    };

    brandData['text']['loading'] = {
        "catch_phrase": {
            "fr": ""
        },
        "explaination": {
            "fr": ""
        }
    };

    brandData['text']['post'] = Object.assign(brandData['text']['post'], brandData['text']['loading']);

    const different_states = {
        "pre": {
            "class": "pre_micro",
            "fonction": () => true,
            "text": {
                "en": "Touch and speak",
                "fr": "Toucher puis parler"
            }
        },
        "recording": {
            "class": "recording_micro",
            "fonction": () => true,
            "text": {
                "en": "Touch to send!",
                "fr": "Toucher pour envoyer !"
            }
        },
        "loading": {
            "class": "loading_micro",
            "fonction": () => {},
            "text": {
                "en": "Sending in progress ...",
                "fr": "Envoi en cours ..."
            }
        },
        "post": {
            "class": "finished_micro",
            "fonction": () => {},
            "text": ""
        }
    }

    if (!postRecording) {
        return (
            <div className="microphone">
                <MainTexts content={utils.getLanguage(brandData['text'][recordingState][preText])} />
                <div>
                    <div className="micro_container">
                        <img onClick={different_states[recordingState]['fonction']} className={"micro_img " + different_states[recordingState]['class']} src={process.env.PUBLIC_URL + "/microv1.svg"}/>
                    </div>
                    <p className="micro_label">{utils.getLanguage(different_states[recordingState]['text'])}</p>
                </div>
            </div>
        )
    } else {
        return(
            <PostRecording brandData={brandData} data={micData}/>
        )
    }
}


function ThankYouModule({texts, sentiment}) {
    const self = useRef()
    const titleElem = useRef();
    const textElem = useRef();

    useEffect(
        () => {
            titleElem.current.style.fontSize = utils.getFontSize(utils.getLanguage(texts['thanks']).length, 41);
            textElem.current.style.fontSize = utils.getFontSize(utils.getLanguage(texts['contextual'][sentiment]).length, 28, 0.05);
        }
    )

    const getSmiley = sent => process.env.PUBLIC_URL + '/smiley/' + sent + '.svg'
    return(
        <div ref={self} className="post_module thank_you">
            <p ref={titleElem} className="thanks">{utils.getLanguage(texts['thanks'])}</p>
            <img src={getSmiley(sentiment)}/>
            <p ref={textElem}>{utils.getLanguage(texts['contextual'][sentiment])}</p>
        </div>
    )
}

function EmailModule({text, filename, brand, color}) {
    const self = useRef();
    const field = useRef();
    
    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
    })

    useEffect(() => {
        let timout;
        const changePlaceholder = (i) => {
            const placeholders = ['john@doe.com', '+33 6 12 34 56 78']
            const j = i === 0 ? 1 : 0;
            field.current.placeholder = placeholders[i]
            timout = setTimeout(
                () => changePlaceholder(j),
                3000
            )
        }
        changePlaceholder(1)
        return () => clearTimeout(timout)
    }, [color])

    const sendEmail = () => {
            utils.updatefield(brand, filename, 'email', field.current.value)
            
            const elem = self.current;
            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
    }
    return(
        <div ref={self} className="post_module">
            <p className="label_title">{text}</p>
            <div className="email_input">
                <input ref={field} type="text" placeholder="john@doe.com"/>
            </div>
            <p className="send_button" style={{color: color}}>{
                utils.getLanguage({
                    "en": "Send",
                    "fr": "Envoyer"
                })
            }</p>
        </div>
    )
}

const RatingModule = ({text, filename, brand}) => {
    const [rating, setRating] = useState(0);
    const self = useRef()

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        if (rating > 0) {
            utils.updatefield(brand, filename, 'rating', rating)

            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
        }
    }, [rating])

    return (
        <div ref={self} className="post_module">
            <p className="label_title">{text}</p>
            <div className="star_container">
                {[1, 2, 3, 4, 5].map(val => 
                    <div className='rating_star' key={val}>
                        <svg viewBox="0 0 100 100" style={{
                            fill: rating >= val ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0)' 
                        }}>
                            <polygon points="50,9 60.5,39.5 92.7,40.1 67,59.5 76.4,90.3 50,71.9 23.6,90.3 32.9,59.5 7.2,40.1 39.4,39.5"/>
                        </svg>
                    </div>
                )}
            </div>
        </div>
    )
}

const GoogleReviewModule = ({url, color, transcript, brandId}) => {
    console.log(transcript)
    return (
        <div className="post_module">
            <p className="label_title">
                {
                    utils.getLanguage({
                        "fr": "Votre avis a été copié dans le presse papier, vous pouvez le coller dans Google Review :",
                        "en": "Your review has been copied to the clipboard, you can paste it into Google Review :"
                    })
                }
            </p>
            <a style={{color: color}} className="google_button" onClick={() => {navigator.clipboard.writeText(transcript)}} href={url} target="_blank"> Google Review </a>
        </div>
    )
}

function PostRecording({brandData, data}) {
    const [iAppear, setIAppear] = useState(0)

    useEffect(() => {
        const t = iAppear == 0 ? 50 : 600;
        if (iAppear <= 3) {
            setTimeout(() => {
                setIAppear(iAppear+1)
            }, 600)
        }
    }, [iAppear])

    const modules = [() => ThankYouModule({texts:brandData['text']['post'], sentiment:data['sentiment']})];

    if (brandData['post_modules'].hasOwnProperty('rating') && !(utils.getParams().hasOwnProperty('rating'))) {
        modules.push(() => RatingModule({text:utils.getLanguage(brandData['post_modules']['rating']), filename: brandData['filename'], brand: brandData['brand']}))
    }
    if (brandData['post_modules'].hasOwnProperty('email') && !(utils.getParams().hasOwnProperty('email'))) {
        modules.push(() => EmailModule ({text:utils.getLanguage(brandData['post_modules']['email']), filename: brandData['filename'], brand: brandData['brand'], color: brandData['color']}))
    }
    if (brandData['post_modules'].hasOwnProperty('google_review') && data['sentiment'] === 'POSITIVE') {
        modules.push(
            () => GoogleReviewModule({url: brandData['post_modules']["google_review"]["url"], color: brandData['color'], transcript: data['text'], brandId: brandData['brand']})
        )
    }

    const appearStyle = {
        transform: "scale(1)",
        opacity: "1",
    }

    return (
        <div className="post_recording">
            {
                modules.map(
                    (v, i) => (
                        <div key={i} className='module_appear' style={iAppear > i ? appearStyle : {}}>
                            {v()}
                        </div>
                    )
                )
            }
        </div>
    )
}


export default WebAppWidget