import { useEffect, useState } from 'react';
import './App.css';
import utils from './utils/utils';
import Dashboard from './Dashboard';
import WebApp from './WebApp';

utils.loginAws()

function PageSwitcher({page, setPage}) {
  return(
    <div className="pageSwitcher">
      <div id="WebApp" onClick={() => setPage('WebApp')} className={"page_button" + (page=="WebApp" ? ' selected': '')}>
        <p>WebApp</p>
      </div>
      <div id="Dashboard" onClick={() => setPage('Dashboard')} className={"page_button" + (page=="Dashboard" ? ' selected': '')}>
        <p>Dashboard</p>
      </div>
      <div className="pageSwitcherOverlay"></div>
    </div>
  )
}

function App() {
  const [page, setPage] = useState('WebApp')

  return(
    <div>
      <PageSwitcher page={page} setPage={setPage}/>
      {(
        () => page == "Dashboard" ? <Dashboard/> : <WebApp/>
      )()}
    </div>
  )
}

export default App;
