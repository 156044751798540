export default [{name: "Afrikaans", value: "af"},
{name: "Arabe", value: "ar"},
{name: "Bangla", value: "bn"},
{name: "Bosniaque (latin)", value: "bs"},
{name: "Bulgare", value: "bg"},
{name: "Cantonais (traditionnel)", value: "yue"},
{name: "Catalan", value: "ca"},
{name: "Croate", value: "hr"},
{name: "Tchèque", value: "cs"},
{name: "Danois", value: "da"},
{name: "Néerlandais", value: "nl"},
{name: "Anglais", value: "en"},
{name: "Estonien", value: "et"},
{name: "Fidjien", value: "fj"},
{name: "Filipino", value: "fil"},
{name: "Finnois", value: "fi"},
{name: "Français", value: "fr"},
{name: "Allemand", value: "de"},
{name: "Grec", value: "el"},
{name: "Goudjrati", value: "gu"},
{name: "Créole haïtien", value: "ht"},
{name: "Hébreu", value: "he"},
{name: "Hindi", value: "hi"},
{name: "Hmong blanc", value: "mww"},
{name: "Hongrois", value: "hu"},
{name: "Indonésien", value: "id"},
{name: "Irlandais", value: "ga"},
{name: "Italien", value: "it"},
{name: "Japonais", value: "ja"},
{name: "Kannada", value: "kn"},
{name: "Kiswahili", value: "sw"},
{name: "Klingon", value: "tlh-Latn"},
{name: "Klingon (plqaD)", value: "tlh-Piqd"},
{name: "Coréen", value: "ko"},
{name: "Letton", value: "lv"},
{name: "Lituanien", value: "lt"},
{name: "Malgache", value: "mg"},
{name: "Malais", value: "ms"},
{name: "Malayalam", value: "ml"},
{name: "Maltais", value: "mt"},
{name: "Maori", value: "mi"},
{name: "Marathi", value: "mr"},
{name: "Norvégien", value: "nb"},
{name: "Persan", value: "fa"},
{name: "Polonais", value: "pl"},
{name: "Portugais (Brésil)", value: "pt-br"},
{name: "Portugais (Portugal)", value: "pt-pt"},
{name: "Pendjabi", value: "pa"},
{name: "Queretaro Otomi", value: "otq"},
{name: "Roumain", value: "ro"},
{name: "Russe", value: "ru"},
{name: "Samoan", value: "sm"},
{name: "Serbe (cyrillique)", value: "sr-Cyrl"},
{name: "Serbe (latin)", value: "sr-Latn"},
{name: "Slovaque", value: "sk"},
{name: "Slovène", value: "sl"},
{name: "Espagnol", value: "es"},
{name: "Suédois", value: "sv"},
{name: "Tahitien", value: "ty"},
{name: "Tamoul", value: "ta"},
{name: "Télougou", value: "te"},
{name: "Thaï", value: "th"},
{name: "Tonga", value: "to"},
{name: "Turc", value: "tr"},
{name: "Ukrainien", value: "uk"},
{name: "Ourdou", value: "ur"},
{name: "Vietnamien", value: "vi"},
{name: "Gallois", value: "cy"},
{name: "Yucatec Maya", value: "yua"}]