const AWS = require('aws-sdk')

class Utils {
    getUrlVars = () => {
        var vars = {};
        window.location.href.replace(/[#&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }
    
    toDataURL(src, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function() {
          var canvas = document.createElement('CANVAS');
          var ctx = canvas.getContext('2d');
          var dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
        };
        img.src = src;
    }

    loginAws = () => {
        AWS.config.region = 'eu-west-2'; // Région
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'eu-west-2:13d810d5-435e-453b-8cd4-c3ea362d4d89',
            Logins: {
                'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_Bm4KP9CbL': this.getUrlVars()['id_token']
            }
        });

        AWS.config.credentials.getPromise().catch(
            () => window.location.replace("https://zevoice-dashboard-beta.auth.eu-west-2.amazoncognito.com/login?client_id=193pbgh938lhpgjf5efeldifc5&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin + window.location.pathname)
        );
    }
}

let utils = new Utils();

export default utils;