import { useEffect, useRef, useState } from 'react';
import './App.css';
import { Multiselect } from 'multiselect-react-dropdown';
import utils from './utils/utils';
import WebAppWidget from './WebAppWidget';
import Creatable from 'react-select/creatable';
import language_list from './languages';

import 'react-tagsinput/react-tagsinput.css'

function WebApp () {

    const sendButton = useRef()
    const saveButton = useRef()


    const [fetchedData, setFetchedData] = useState({});
    const [brand, setBrand] = useState('')
    const [languages, setLanguages] = useState([{name: "Anglais", value:"en"}, {name: "Français", value:"fr"}])
    const [color, setColor] = useState('#254080')
    const [logo, setLogo] = useState('')
    const [logoWidth, setLogoWidth] = useState(50)
    const [dispLanguage, setDispLanguage] = useState('fr')
    const [dispSentiment, setDispSentiment] = useState('POSITIVE')
    const [alternative, setAlternative] = useState('')
    const [alternatives, setAlternatives] = useState({label: 'default', value: 'default'});
    const [inputAlternative, setInputAlternative] = useState('')
    const [dataText, setDataText] = useState({
        'pre': {
            'catch_phrase': {
                'en': 'Dear customer, we are listening to you!',
                'fr': 'Cher client, nous sommes à votre écoute !'
            },
            'explaination': {
                'en': 'Tell us about your experience.',
                'fr': 'Parlez-nous de votre expérience.'
            }
        },
        'post': {
            'thanks': {
                'en': 'Thank you for your message!',
                'fr': 'Merci pour votre message !'
            },
            'contextual': {
                'POSITIVE': {
                    'en': 'We are glad you had a good experience!',
                    'fr': 'Nous sommes heureux que vous ayez eu une bonne expérience !'
                },
                'NEGATIVE': {
                    'en': 'We\'re sorry you had a bad experience. You can leave us your phone number or email address so that we can contact you.',
                    'fr': 'Nous sommes désolés que vous ayez eu une mauvaise expérience. Vous pouvez nous laisser votre numéro de téléphone ou adresse email afin que nous puissions vous contacter.'
                },
                'NEUTRAL': {
                    'en': 'Your opinion is very important to us! You can leave us your phone number or email address so that we can contact you.',
                    'fr': 'Votre avis compte beaucoup pour nous ! Vous pouvez nous laisser votre numéro de téléphone ou adresse email afin que nous puissions vous contacter.'
                },
                'MIXED': {
                    'en': 'We understand that your experience was not perfect. You can leave us your phone number or email address so that we can contact you.',
                    'fr': 'Nous avons bien compris que votre expérience n\'ai pas été parfaite. Vous pouvez nous laisser votre numéro de téléphone ou adresse email afin que nous puissions vous contacter.'
                },
            }
        }
    })

    const languageTranslate = {}

    language_list.forEach(
        v => languageTranslate[v['value']] = v['name']
    )

    const formTranslate = {...languageTranslate, ...{
        'pre': 'Écran d\'accueil',
        'catch_phrase': 'Phrase d\'accroche',
        'explaination': 'Éxplication',
        'post': 'Écran post-enregistrement',
        'thanks': 'Remerciement',
        'contextual': 'Message contextuel',
        'POSITIVE': 'En cas de retour positif',
        'NEGATIVE': 'En cas de retour négatif',
        'NEUTRAL': 'En cas de retour neutre',
        'MIXED': 'En cas de retour mitigé'
    }}

    useEffect(
        () => {
            async function fetchWebAppData () {
                return await fetch(
                    process.env.REACT_APP_MASTER_API + "/LoadBrandWebapp",
                    {
                        method: "GET",
                        headers: {
                            "Authorization": utils.getUrlVars()['access_token']
                        }
                    }
                ).then(resp => {
                    if (resp.status == 400) {
                        console.error('Unable to load data', resp.text())
                    }
                    return resp.json()
                }).then(
                    x => {
                        let message = x['message']
                        setFetchedData(message);
                        console.log(message)
                        if (typeof(message['color']) == 'object') {
                            setAlternative(Object.keys(message['color'])[0]);
                            setAlternatives(Object.keys(message['color']).map(v => ({value: v, label: v})));
                        } else {
                            setAlternative('default')
                        }
                    }
                ).catch(
                    err => console.log(err)
                )
            }
            fetchWebAppData()
        }
    , [])

    useEffect(
        () => {
            let data = JSON.parse(JSON.stringify(fetchedData));
            if (Object.keys(data).length > 0) {
                if (typeof(data['color']) === 'object') {
                    if (Object.keys(data['color']).includes(alternative)) {
                        data['image']['image'][alternative] = data['image']['image'][alternative][0] == '.' ? 'https://app.alloreview.com' + data['image']['image'][alternative].slice(1) : data['image']['image'][alternative];
                        setLogo(data['image']['image'][alternative])
                        setLogoWidth(data['image']['image_width'][alternative])
                        setColor(data['color'][alternative])
                        setDataText(data['text'][alternative])
                        setBrand(data['brand'])
                    }
                }
                else {
                    data['image']['image'] = data['image']['image'][0] == '.' ? 'https://app.alloreview.com' + data['image']['image'].slice(1) : data['image']['image'];
                    setLogo(data['image']['image'])
                    setLogoWidth(data['image']['image_width'])
                    setColor(data['color'])
                    setDataText(data['text'])
                    setBrand(data['brand'])
                }
            }
        }
    ,  [alternative])

    useEffect(
        () => {
            let data = JSON.parse(JSON.stringify(fetchedData));
            if (Object.keys(data).length > 0) {
                data['image']['image'][alternative] = logo
                data['image']['image_width'][alternative] = logoWidth
                data['color'][alternative] = color
                data['text'][alternative] = dataText
                setFetchedData(data);
            }
        }
    , [logo, logoWidth, dataText, color])

    const saveWebAppModifs = publish => {
        sendButton.current.style.display = 'none'
        saveButton.current.style.display = 'none'

        return fetch(
            process.env.REACT_APP_MASTER_API + "/SaveBrandWebapp",
            {
                method: "POST",
                headers: { "Authorization": utils.getUrlVars()['access_token'] },
                body: JSON.stringify({
                    'text': fetchedData['text'],
                    'color': fetchedData['color'],
                    'image': fetchedData['image'],
                    'publish': publish
                })
            }
        ).then(resp => {
            if (resp.status == 400) {
                console.error('Unable to load data', resp.text())
            }
            sendButton.current.style.display = ''
            saveButton.current.style.display = ''
            sendButton.current.innerHTML = 'Sauvegardé !'
            setTimeout(() => sendButton.current.innerHTML = 'Appliquer', 2000)
            return resp.json()
        }).then(
            console.log
        ).catch(
            console.warn
        )
    }

    const handleLanguagesChanges = e => {
        const currentLanguages = Object.keys(dataText['pre']['catch_phrase'])
        const newLanguages = e.map(v => v['value']).length === 0 ? currentLanguages : e.map(v => v['value']);
        const reccurentLg = (formData) => {
            if (typeof(formData[Object.keys(formData)[0]]) === 'string') {
                for (const lg of currentLanguages) {
                    if (!newLanguages.includes(lg)) {
                        delete formData[lg]
                    }
                }
                for (const lg of newLanguages) {
                    if (!currentLanguages.includes(lg)) {
                        formData[lg] = ''
                    }
                }
            } else {
                for (const k in formData) {
                    reccurentLg(formData[k])
                }
            }
        }
        reccurentLg(dataText)
        setLanguages(e)
    }

    const reccurentForm = (formData, keys) => {
        if (typeof(formData) === 'string') {
            const handleChange = x => {
                let head = dataText;
                for (const k of keys.slice(0, -1)) {
                    head = head[k]
                }
                head[keys.slice(-1)[0]] = x.target.value
                setDataText({...dataText})
                return true
            }
            return (
                <span className='input_wrapper' style={{width: '100%'}}><input onChange={handleChange} value={formData} type="text"/></span>
            )
        } else {
            return (
                <>
                    {
                        Object.keys(formData).map((k, i) => {
                            return(<div key={i} className='field' style={{width: '90%'}}>
                                <span className='field_name' style={{width: '25%'}}>{formTranslate.hasOwnProperty(k) ? formTranslate[k] : k} :</span>
                                {(() => ['pre', 'post'].includes(k) ? <>
                                    <br/><WebAppWidget brandData={{"brand": "alloreview", "brand_name": "AlloReview", "color": color, "image": logo, "image_width": logoWidth + "%", "text": {"pre": {"catch_phrase":  dataText['pre']['catch_phrase'], "explaination": dataText['pre']['explaination']}, "post": {"thanks": dataText['post']['thanks'], "contextual": dataText['post']['contextual']}}, "post_modules": {"email": {"en": "If you wish to be contacted :", "fr": "Si vous souhaitez \u00eatre recontact\u00e9 :"}, "rating": {"en": "You can also add a satisfaction note :", "fr": "Vous pouvez aussi ajouter une note de satisfaction :"}}, "autres": {"listening": {"en": "We are listening ...", "fr": "On vous \u00e9coute ..."}}}} micData={{'sentiment': dispSentiment}} language={dispLanguage} recordingState={k}/>
                                    <br/>
                                    <p style={{textAlign: 'center'}}>
                                        <label>Langue de l'appli : </label><select value={dispLanguage} onChange={e => setDispLanguage(e.target.value)}>
                                            {languages.map(
                                                (v,i) => <option value={v['value']} key={i}>{v['name']}</option>
                                            )}
                                        </select>
                                    </p>
                                </> : '' )()}
                                {(() => k == 'post' ?
                                    <p style={{textAlign: 'center'}}>
                                        <select value={dispSentiment} onChange={e => setDispSentiment(e.target.value)}>
                                            {[['POSITIVE', 'Client satisfait'], ['NEGATIVE', 'Client insatisfait'], ['MIXED', 'Client mitigé'], ['NEUTRAL', 'Client neutre']].map(
                                                (v,i) => <option value={v[0]} key={i}>{v[1]}</option>
                                            )}
                                        </select>
                                    </p> : '' )()}
                                {reccurentForm(formData[k], keys.concat([k]))}
                            </div>)
                        })
                    }
                </>
            )
        }
    }

    const handleAlternativeCreation = value => {
        setInputAlternative('')
        console.log('Created')
        let data = JSON.parse(JSON.stringify(fetchedData));
        data['color'][value] = data['color'][alternative]
        data['image']['image_width'][value] = data['image']['image_width'][alternative]
        data['image']['image'][value] = data['image']['image'][alternative]
        data['text'][value] = {...data['text'][alternative]}
        setFetchedData(data);
        setAlternative(value)
        setAlternatives(
            [...alternatives, {label: value, value: value}]
        )
    }

    const handleAlternativeChange = e => {
        setAlternative(e['value'])
    }

    const deleteAlternative = () => {
        let newAlternatives = JSON.parse(JSON.stringify(alternatives));
        newAlternatives = newAlternatives.filter(
            v => v['value'] != alternative
        );
        delete fetchedData['text'][alternative];
        delete fetchedData['color'][alternative];
        delete fetchedData['image']['image_width'][alternative];
        delete fetchedData['image']['image'][alternative];
        setAlternatives(newAlternatives)
        setAlternative(newAlternatives[0]['value'])
    }
    
    const handleInputChange = txt => {
        setInputAlternative(
            txt.replace(/[^a-z0-9_]*/g, '')
        )
    }

    return(
        <div className='App'>
            <h1>Configurer votre WebApp</h1>
            <img alt="main" className="main_img" src="welcome.jpg"/>
            <p className="description">
                Vous pouvez personnaliser la WebApp que vos clients utiliseront pour vous parler de leur expérience.
            </p>
            <div className='formulaire'>
                <div className='field' style={{width: '50%'}}>
                    <span className='field_name'>Version :</span>
                    <span className='input_wrapper'>
                        <Creatable
                            options={alternatives}
                            onCreateOption={handleAlternativeCreation}
                            onChange={handleAlternativeChange}
                            onInputChange={handleInputChange}
                            inputValue={inputAlternative.length > 0 ? inputAlternative : undefined}
                            value={{value: alternative, label: alternative}}
                        />
                        <a style={{color: 'darkblue', cursor: 'pointer', textDecoration: 'underline'}} onClick={deleteAlternative}>Supprimer</a>
                    </span>
                </div>
                <div className='field'>
                    <span className='field_name'>Votre logo :</span>
                    <span className='input_wrapper'><input onChange={e => utils.toDataURL(URL.createObjectURL(e.target.files[0]), setLogo)} type="file"/></span>
                </div>
                <div className='field'>
                    <span className='field_name'>Dimensions du logo :</span>
                    <span className='input_wrapper'><input value={logoWidth} onChange={e => setLogoWidth(e.target.value)} type="range" min="0" max="100"/></span>
                </div>
                <div className='field'>
                    <span className='field_name'>Couleur de fond :</span>
                    <span className='input_wrapper'><input value={color} onChange={e => setColor(e.target.value)} type="color"/></span>
                </div>
                <div className='field'>
                    <span className='field_name'>Languagues disponibles :</span>
                    <span className='input_wrapper'><Multiselect selectedValues={languages} options={language_list} onSelect={handleLanguagesChanges} onRemove={handleLanguagesChanges} displayValue="name"/></span>
                </div>
                {reccurentForm(dataText, [])}
            </div>      
            <a target="_blank" href={process.env.REACT_APP_WEBAPP + "/brand/" + brand + '?alt=' + alternative}>https://app.alloreview.com/brand/{brand}?alt={alternative}</a>
            <p className="save_button" ref={saveButton} onClick={() => saveWebAppModifs(false)}>Sauvegarder</p>
            <p className="send_button" ref={sendButton} onClick={() => saveWebAppModifs(true)}>Appliquer</p>
        </div>
    )
}

export default WebApp;