import { useEffect, useState } from 'react'
import './App.css';
import utils from './utils/utils'

function DictionnaryForm({callback, dictionnaryData}) {
    const placeholder = [
      ['Prix et paiement', 'prix paiement coût cher payer'],
      ['Qualité des produits', 'produit qualité ordinateur téléphone imprimantes'],
      ['Accueil en magasin', 'accueil vendeur agréable ambiance chalereux sympathique'],
      ['Livraison', 'livré retard poste livreur carton'],
    ]
    const rd = Math.floor(Math.random() * (placeholder.length - 1));
    const [dictionnary, setDictionnary] = useState([['', '', placeholder[rd][0], placeholder[rd][1]]])
  
    useEffect(
      () => {
        const output = [];
        for (const key in dictionnaryData) {
          const rd = Math.floor(Math.random() * (placeholder.length - 1));
          output.push([key, dictionnaryData[key].join(' '), placeholder[rd][0], placeholder[rd][1]])
        }
        output.push(['', '', placeholder[rd][0], placeholder[rd][1]])
        setDictionnary(output)
      }
    , [dictionnaryData])
  
    const handleDictionnaryChange = (e, i, tp) => {
      const target = e.target
      const new_dictionnary = []
      for (const j in dictionnary) {
        if (parseInt(j) === parseInt(i)) {
          const val = [...dictionnary[j]]
          val[tp] = target.value
          new_dictionnary.push(val)
        } else {
          if (dictionnary[j][0] !== '' || dictionnary[j][1] !== '' || parseInt(j) === dictionnary.length - 1) {
            new_dictionnary.push(dictionnary[j])
          }
        }
      }
      if (new_dictionnary[new_dictionnary.length - 1][0] !== '' || new_dictionnary[new_dictionnary.length - 1][1] !== '') {
        const rd = Math.floor(Math.random() * (placeholder.length - 1));
        new_dictionnary.push(['', '', placeholder[rd][0], placeholder[rd][1]])
      }
  
      if (parseInt(tp) === 1) {
        new_dictionnary[i][tp] = new_dictionnary[i][tp].replace(/[.,!;:{}=`~()]/g,"")
      }
  
      setDictionnary(new_dictionnary)
      callback(dictionnary)
    }
  
  
  
    return(
      <div className="dict">
        {
          dictionnary.map((v, i) => {
            return <div key={i} className="dic_cat">
              <span className='input_wrapper dic_name'>
                <p className='input_label'>Nom de l'étape de parcours :</p>
                <input type="text" placeholder={v[2]} value={v[0]} onFocus={e => handleDictionnaryChange(e, i, 0)} onChange={e => handleDictionnaryChange(e, i, 0)}/>
              </span>
              <span className='input_wrapper dic_words'>
                <p className='input_label'>Mots clés :</p>
                <input type="text" placeholder={v[3]} value={v[1]} onFocus={e => handleDictionnaryChange(e, i, 1)} onChange={e => handleDictionnaryChange(e, i, 1)}/>
              </span>
            </div>
          })
        }
      </div>
    )
}
  
function MainForm({sendCallback, formData}) {
const [errors, setErrors] = useState([])
const [trustpilotUrl, setTrustpilotUrl] = useState('')
const [data, setData] = useState({
    dictionnary: [{}, false],
    url: ['', true],
})

useEffect(
    () => {
      const trustpilot_url = formData.hasOwnProperty('scrapping') ? formData['scrapping']['trustpilot_url'] : ''
      console.log(formData.hasOwnProperty('dictionnary') ? formData['dictionnary'] : {})
      setData({
          dictionnary: [formData.hasOwnProperty('dictionnary') ? formData['dictionnary'] : {}, true],
          url: [trustpilot_url, true],
      })
      setTrustpilotUrl(trustpilot_url)
    }
, [formData])

const handleSending = () => {
    let checked = true
    const error = {
    'url': 'Veuillez vérifier l\'url Trustpilot, elle doit avoir le format suivant : https://fr.trustpilot.com/review/******',
    'dictionnary': 'Veuillez vérifier les Catégories de suivis, il faut au moins 5 mots par catégorie.',
    }
    const new_errors = []
    for (const k of Object.keys(data)) {
      checked = data[k][1] && checked
      if (!data[k][1]) {
          new_errors.push(
          error[k]
          )
      }
    }
    if (!checked) {
      setErrors(new_errors)
    } else {
      document.getElementsByClassName('send_button')[0].style.display = 'none'
      setErrors(new_errors)
      fetch(
          process.env.REACT_APP_MASTER_API + "/MasterApi",
          {
              method: "POST",
              headers: { "Authorization": utils.getUrlVars()['access_token'] },
              body: JSON.stringify({
              dictionnary: data['dictionnary'][0],
              url: data['url'][0],
              token: utils.getUrlVars()['access_token']
              })
          }
      ).then(
          x => {
          document.getElementsByClassName('send_button')[0].style.display = ''
          if (x.status === 400) {
              return x.json().then(
              y => {
                  console.log('Error', y)
                  return setErrors([{
                  'Vous avez déja créé votre dashboard !': 'Il existe déjà un dashboard sur ce compte !',
                  }[y['message']]])
              }
              )
          }
          sendCallback()
          return x.json().then(console.log)
          }
      )
    }
}

const handleSave = () => {
    document.getElementsByClassName('save_button')[0].style.display = 'none'
    fetch(
    process.env.REACT_APP_MASTER_API + "/MasterApi",
    {
        method: "POST",
        headers: { "Authorization": utils.getUrlVars()['access_token'] },
        body: JSON.stringify({
            save: true,
            dictionnary: data['dictionnary'][0],
            url: data['url'][0],
        })
    }
    ).then(
    x => {
        document.getElementsByClassName('save_button')[0].style.display = ''
        if (x.status === 400) {
        return x.json().then(
            y => {
            console.log('Error', y)
            return setErrors([{
                'Vous avez déja créé votre dashboard !': 'Il existe déjà un dashboard sur ce compte !',
            }[y['message']]])
            }
        )
        }
        return x.json().then(console.log)
    }
    )
}

const dictionnaryCallback = (value) => {
    const dict = Object.fromEntries(value)
    delete dict['']
    data['dictionnary'] = [dict, false]
    let checked_dict = true
    for (const k of Object.keys(data['dictionnary'][0])) {
    data['dictionnary'][0][k] = data['dictionnary'][0][k].split(' ')
    checked_dict = checked_dict && data['dictionnary'][0][k].length > 4
    }
    if (Object.keys(data['dictionnary'][0]).length > 1 && checked_dict) {
    data['dictionnary'][1] = true
    } else {
    data['dictionnary'][1] = false
    }
    setData(data)
}

const handleTrustpilotUrl = (e) => {
    const target = e.target
    setTrustpilotUrl(target.value)
    data['url'] = [target.value.split('?')[0], false]
    if (data['url'][0].includes('trustpilot.com') || data['url'][0] === '') {
    data['url'][1] = true
    target.parentNode.classList.remove('not_validated')
    } else {
    data['url'][1] = false
    target.parentNode.classList.add('not_validated')
    }
    setData(data)
}

return (
    <div>
    <h1>Bienvenue chez AlloReview !</h1>
    <img alt="main" className="main_img" src="welcome.jpg"/>
    <p className="description">
      Nous sommes très heureux de vous compter parmi nous. Afin que nous puissions vous créer un dashboard, nous aurons besoin de quelques informations.
      <br/>Une fois ce formulaire rempli, nous vous enverrons un e-mail pour accéder à votre Dashboard.
    </p>
    <div className='formulaire'>
        <div className='field'>
        <span className='field_name'>Url Trustpilot (optionnel) :</span>
        <span className='input_wrapper'><input type="text" value={trustpilotUrl} onChange={handleTrustpilotUrl} placeholder="https://fr.trustpilot.com/review/www.fnac.com"/></span>
        </div>
        <div className='field' style={{marginTop: '40px', marginBottom: '15px'}}>
        <span className='field_name'>Catégories suivies :</span><br/>
        </div>
        <span className='field_comment'>
        Sur votre dashboard AlloReview vous pourrez suivre l'évolution de la satisfaction client selon différentes "étapes de parcours".<br/>
        Afin de définir ces étapes de parcours, veuillez nous donner le nom de l'étape et entre 5 et 10 mots clés associés, séparés par un espace.<br/>
        Nos algorithme utiliseront ces mots clés et les extrapolerons pour classifier et analyser les retours de vos clients.<br/>
        Pour avoir les meilleurs performances possibles veillez à que les mots clés ne soit pas trop générique et que deux catégories ne contiennent pas des mots clés sémantiquement trop similaires.
        </span>
        <div className='field'>
        </div>
    </div>
    <DictionnaryForm callback={dictionnaryCallback} dictionnaryData={formData['dictionnary']}/>
    <span className='field_comment'>
        {
        errors.map((v, i) => {
            return <span style={{color:'red'}} key={i}>{v}<br/></span>
        })
        }
    </span>
    <p className="save_button" onClick={handleSave}>Sauvegarder</p>
    <p className="send_button" onClick={handleSending}>Envoyer</p>
    </div>
);
}


const Dashboard  = () => {
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({})

  useEffect(
      () => {
      async function get_data() {
          return await fetch(
          process.env.REACT_APP_MASTER_API + "/LoadDashboardData",
          {
              method: "GET",
              headers: {
              "Authorization": utils.getUrlVars()['access_token']
              }
          }
          ).then(resp => {
          if (resp.status == 400) {
              console.error('Unable to load data', resp.text())
          }
          return resp.json()
          }).then(
          x => setFormData(x['message'])
          ).catch(
          err => console.log(err)
          )
      }
      get_data();
      }
  , [])

    const sendCallback = () => setSent(true)

    if (!sent) {
        return (
        <div className="App">
            <MainForm sendCallback={sendCallback} formData={formData}/>
        </div>
        );
    } else {
        return(
        <div className="App">
            <h1>Votre dashboard est bientôt prêt !</h1>
            <img alt="main" className="main_img" src="welcome.jpg"/>
            <p>
            Notre système est entrain de récupérer et d'analyser les avis, nous vous enverrons un mail dés que votre dashboard sera prêt !
            </p>
        </div>
        );
    }
}
  
export default Dashboard;
  