class Utils {
    constructor() {
        this.setupLanguage = 'fr';
    }

    fetchBrandData(brandId) {
        return fetch(
            process.env.PUBLIC_URL + '/brands/' + brandId + '.json',
            {
                method: "GET"
            }
        ).then(
            resp => {
                if (!resp.ok) {
                    return new Promise(res => res(resp))
                }
                return resp.json()
            }
        )
    }

    getFontSize = (textLength, baseSize, coef) => {
        coef = coef || 0.2;
        const fontSize = baseSize - coef*textLength;
        return `${fontSize}px`
    }

    manage_links(text) {
        //console.error('Coucou')
        //console.error('text', text)
        const find = /\[.*?\]\(.*?\)/g
        let parts = text.split(find);
        let matches = text.match(find);
        const result = []
        for(let i = 0; i < parts.length; i++) {
            result.push(<span key={i}>{parts[i]}</span>);
            if (matches && i < matches.length) {
                const params = matches[i].match(/\[(.*?)\]\((.*?)\)/)
                if (params && params.length >=3) {
                    result.push(
                        <a style={{color: "lightblue"}} href={params[2]} target="_blank" key={i + "_link"}>{params[1]}</a>
                    );
                }
            }
        }
        return result;
    }

    getLanguage(dict) {
        if (typeof(dict) === "string") {
            return dict;
        }
        const lgs = [this.setupLanguage].map(
            x => x.substring(0, 2)
        )

        for (const lg of lgs) {
            if (dict.hasOwnProperty(lg)) {
                return this.manage_links(dict[lg])
            }
        }

        return this.manage_links(dict[Object.keys(dict)[0]])
    }

    drkColor(colorCode, amount) {
        var usePound = false;
     
        if (colorCode[0] == "#") {
            colorCode = colorCode.slice(1);
            usePound = true;
        }
     
        var num = parseInt(colorCode, 16);
     
        var r = (num >> 16) + amount;
     
        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
            return "#000000"
        }
     
        var b = ((num >> 8) & 0x00FF) + amount;
     
        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
            return "#000000"
        }
     
        var g = (num & 0x0000FF) + amount;
     
        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
            return "#000000"
        }
        console.log(("0" + g.toString(16)).slice(-2))
        return (usePound ? "#" : "") + ("0" + (r << 16).toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + (b << 8).toString(16)).slice(-2);
    }

    getParams () {
        var params = {};
        var parser = document.createElement('a');
        const url = document.URL
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };
}

const utils = new Utils();

export default utils;